import React, { useState } from "react"
import { graphql } from "gatsby"
import useForm from "react-hook-form"
import Styled from "styled-components"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import RuledBreak from "../../components/RuledBreak"
import { ButtonTrace } from "../../components/Button"

import { URLS } from "../../config"

const Form = Styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	label, input, textarea {
		width: 100%;
    	box-sizing: border-box;
		display: flex;
		justify-content: center;
		background-color: ${({ theme }) => theme.input};;
		padding: 10px 15px;
		border: 0;
		color: ${({ theme }) => theme.onSurface};
		margin-bottom: 3px;
		font-family: ${({ theme }) => theme.fontSecondary};
		-webkit-appearance: none;
		&:-webkit-autofill {
    		-webkit-box-shadow: ${({ theme }) => `0 0 0 100px ${theme.input} inset`};
			-webkit-text-fill-color: ${({ theme }) => theme.onSurface};
		}
		&::-webkit-input-placeholder {
			color: ${({ theme }) => theme.onSurface};
		}
		&::-moz-placeholder {
			color: ${({ theme }) => theme.onSurface};
		}
		&::-ms-input-placeholder {
			color: ${({ theme }) => theme.onSurface};
		}
		&::-moz-placeholder {
			color: ${({ theme }) => theme.onSurface};
		}
	}
	input:focus, textarea:focus {
		outline: ${({ theme }) =>
      theme ? `${theme.primary} auto 5px` : "#000000 auto 5px"}
	}
	textarea {
		resize: none;
		min-height: 150px;
    	margin-bottom: 5px;
	}
	button {
		margin-top: 10px;
	}
	input, textarea {
		width: 100%;
	}
`
const Div = Styled.div`
	margin-top: -48px;
	height: 150px;
	background-color: ${({ theme }) => theme.onBackground};
	display: flex;
	justify-content: center;
	align-items: center;
	h3 {
		font-family: ${({ theme }) => theme.fontSecondary};
		color: ${({ theme }) => theme.primary};
	}
`
const SVG = Styled.svg`
	path {
		stroke: ${({ theme }) => theme.onBackground};
		fill: ${({ theme }) => theme.onBackground};
	}
`
const Success = Styled.p`
  color: ${({ theme }) => theme.secondary};
  padding-top: 3px;
`
const Err = Styled.p`
	color: ${({ theme }) => theme.error};
	padding-top: 3px;
`

const Contact = ({ data }) => {
  const { register, handleSubmit, errors, setValue } = useForm()
  const [errorSubmit, setErrorSubmit] = useState(null)
  const onSubmit = async data => {
    try {
      const { name, email, phone, city, state, tattoo, time, questions } = data
      if (
        name &&
        email &&
        phone &&
        city &&
        state &&
        tattoo &&
        time &&
        questions
      ) {
        // send to contact handler

        // clear inputs
        setValue("name", "")
        setValue("email", "")
        setValue("phone", "")
        setValue("city", "")
        setValue("state", "")
        setValue("tattoo", "")
        setValue("time", "")
        setValue("questions", "")
        setErrorSubmit("")
      } else {
        setErrorSubmit("Must fill in all fields")
      }
    } catch (error) {
      console.log(`CatchError: ${error}`)
      setErrorSubmit("Some bad shit happened - check logs")
    }
  }
  const renderContactStatus = () => {
    if (errorSubmit) {
      return (
        <div className="col-12 text-center">
          <Err>{errorSubmit}</Err>
        </div>
      )
    } else {
      return null
    }
  }
  const header = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={header}
      alt={"Contact Header"}
      height={600}
      heightMobile={300}
    >
      <SEO
        title="Contact"
        keywords={["Contact", "Beyond The Apprenticeship", "Matt Pehrson"]}
      />
      <div className="row">
        <Div className="col-12">
          <h3>
            Please contact us with any questions, or to discuss the program.
          </h3>
        </Div>
        <div className="col-12 pr-0 pl-0">
          <SVG
            preserveAspectRatio="none"
            viewBox="0 0 100 102"
            height="75"
            width="100%"
            version="1.1"
          >
            <path d="M0 0 L50 100 L100 0 Z"></path>
          </SVG>
        </div>
        <div className="col-12 py-4">
          <RuledBreak>Contact Me</RuledBreak>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <h3>
            We would love to set up a personal phone call to explain how the
            program can assist you in becoming more successful!
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 mr-auto ml-auto">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    ref={register({
                      required: "Name is required",
                      maxLength: 100,
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.name && <Err>{errors.name.message}</Err>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    ref={register({
                      required: "Email is required",
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.email && <Err>{errors.email.message}</Err>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone"
                    ref={register({
                      required: "Phone is required",
                      pattern: {
                        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                        message: "Invalid phone number",
                      },
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.phone && <Err>{errors.phone.message}</Err>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    ref={register({
                      required: "City is required",
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.city && <Err>{errors.city.message}</Err>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    name="state"
                    placeholder="State"
                    ref={register({
                      required: "State is required",
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.state && <Err>{errors.state.message}</Err>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    name="tattoo"
                    placeholder="Tattoo Studio"
                    ref={register({
                      required: "Tattoo Studio is required",
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.tattoo && <Err>{errors.tattoo.message}</Err>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    name="time"
                    placeholder="Time In Industry"
                    ref={register({
                      required: "Time In Industry is required",
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.time && <Err>{errors.time.message}</Err>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12">
                  <textarea
                    type="text"
                    name="questions"
                    placeholder="Your Questions"
                    ref={register({
                      required: "Your Questions are required",
                    })}
                  />
                </div>
                <div className="col-12">
                  {errors.questions && <Err>{errors.questions.message}</Err>}
                </div>
              </div>
              {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
              <div className="row">
                <div className="col-12 text-center">
                  <ButtonTrace onClick={handleSubmit(onSubmit)} btn={3}>
                    Submit
                  </ButtonTrace>
                </div>
                {renderContactStatus()}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query contactQuery {
    header: allFile(filter: { name: { eq: "headerContact_1300x676" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Contact
